import React from 'react'
import cx from 'classnames'
import { Container } from '../Layout'
import { Section, SectionProps } from './Section'

export type Column = {
  icon?: React.ReactElement
  content?: React.ReactElement
}

type SectionColumnProps = SectionProps & {
  data?: Array<Column>
  gap?: string
  variant?: string
  boxClass?: string
}

const defaultProps: Partial<SectionColumnProps> = {
  data: [],
  gap: '',
  variant: '',
  boxClass: '',
}

const SectionTableColumns = (props: SectionColumnProps): React.ReactElement => {
  const { data, gap, containerClass, variant, boxClass } = props
  let boxStyle = {}
  if (variant === 'lightGrey') {
    boxStyle = {
      backgroundColor: '#e6e7ed',
      borderRadius: '10px',
    }
  }
  return (
    <Section containerClass={containerClass}>
      <Container>
        {data && (
          <div
            className={cx(
              `grid grid-cols-1 md:grid-cols-${data.length}`,
              gap || 'gap-16 md:gap-1'
            )}
          >
            {data?.map(({ icon, content }) => (
              <div className={boxClass} style={boxStyle}>
                {icon}
                {content}
              </div>
            ))}
          </div>
        )}
      </Container>
    </Section>
  )
}
SectionTableColumns.defaultProps = defaultProps

export { SectionTableColumns }
