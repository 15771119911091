import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import Theme from '../components/theme'
import {
  SEO,
  Layout,
  SectionHero,
  SectionText,
  SectionBanner,
  SectionColumns,
  SectionInfo,
  Button,
} from '../components'
import { Headline } from '../components/Typography/Headline'
import page from '../data/pages/solutions.yml'
import codeGif from '../images/code-editor-sequence.gif'
import whiteCheck from '../images/loesungen_assetts/whitecheck.png'
import blackcheck from '../images/loesungen_assetts/blackcheck.png'
import socialcasback from '../images/socialcashback_weiss_untereinander.svg'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'
import { SectionTableColumns } from '../components/Section/SectionTableColumns'
import { Section } from '../components/Section/Section'
import { removeSlashes } from '../lib'

const tableColumnIconStyle: React.CSSProperties = {
  position: 'relative',
  left: 'calc(50% - 47px)',
  top: '-70px',
  marginBottom: '-70px',
}

const liStyles: React.CSSProperties = {
  listStyle: 'none',
  background: `url(${blackcheck}) no-repeat left center`,
  backgroundSize: '1.8rem',
  padding: '0.5rem 0 0.5rem 2.5rem',
}

const paymentTransactionsColumn = [
  {
    icon: (
      <StaticImage
        src="../images/loesungen_assetts/one-time-payments_icon.png"
        alt="one-time-payments_icon"
        style={tableColumnIconStyle}
      />
    ),
    content: (
      <>
        <Headline fontSize="small" variant="blueUnderline" className="pb-4">
          Einmalzahlungen
        </Headline>
        <p className="text-gray-charcoal font-thin">
          Mit wenigen Klicks &uuml;berweisen deine Kund:innen offene Zahlungen
          direkt auf dein Unternehmenskonto. Und das sicher, schnell und&nbsp;
          <a className="text-brand" href="/de/nachhaltigkeit">
            <strong>inklusive Nachhaltigkeit</strong>
          </a>
          . Mit jeder Transaktion w&auml;hlen deine Kund:innen ein Projekt aus,
          welches wir ohne Mehrkosten f&uuml;r dich oder deine Kund:innen
          unterst&uuml;tzen.
        </p>
        <Headline
          className="pt-10 pb-4"
          fontSize="xsmall"
          variant="blueUnderline"
        >
          Top3 Benefits
        </Headline>
        <ul>
          <li
            className="text-gray-charcoal font-thin"
            key="Zahlungen gehen direkt auf Ihr Hausbankkonto"
            style={liStyles}
          >
            Zahlungen gehen direkt auf dein Hausbankkonto
          </li>
          <li
            className="text-gray-charcoal font-thin"
            key="Zahlungen gehen direkt auf Ihr Hausbankkonto"
            style={liStyles}
          >
            Kund:innen zahlen mit wenigen Klicks vom Bankkonto
          </li>
          <li
            className="text-gray-charcoal font-thin"
            key="Zahlungen gehen direkt auf Ihr Hausbankkonto"
            style={liStyles}
          >
            Inkl. Nachhaltigkeit &amp; Social Cashback
          </li>
        </ul>
      </>
    ),
  },
  {
    icon: (
      <StaticImage
        src="../images/loesungen_assetts/recurring_payments_icon.png"
        alt="recurring_payments_icon"
        style={tableColumnIconStyle}
      />
    ),
    content: (
      <>
        <Headline fontSize="small" variant="blueUnderline" className="pb-4">
          Wiederkehrende Zahlungen
        </Headline>
        <p className="text-gray-charcoal font-thin">
          &Uuml;ber payactive wickelst Du deine wiederkehrenden Zahlungen
          automatisiert ab. Alle Zahlungen gehen direkt auf dein Bankkonto –
          schnellere Liquidit&auml;t f&uuml;r dein Unternehmen. Auch hier
          unterst&uuml;tzt Du mit jeder Transaktion mit deinen Kund:innen
          zusammen soziale oder nachhaltige Projekte – ohne Mehrkosten.
        </p>
        <Headline
          className="pt-10 pb-4"
          fontSize="xsmall"
          variant="blueUnderline"
        >
          Top3 Benefits
        </Headline>
        <ul>
          <li
            className="text-gray-charcoal font-thin"
            key="Zahlungen gehen direkt auf Ihr Hausbankkonto"
            style={liStyles}
          >
            Voll digitales Lastschrift-Setup f&uuml;r deine Kund:innen
          </li>
          <li
            className="text-gray-charcoal font-thin"
            key="Zahlungen gehen direkt auf Ihr Hausbankkonto"
            style={liStyles}
          >
            Inkl. Kund:innenpr&uuml;fung &amp; Bankdaten-Check
          </li>
          <li
            className="text-gray-charcoal font-thin"
            key="Zahlungen gehen direkt auf Ihr Hausbankkonto"
            style={liStyles}
          >
            Perfekt f&uuml;r Abonnements &amp; wiederkehrende Zahlungen
          </li>
        </ul>
      </>
    ),
  },
]

const iconStyle: React.CSSProperties = {
  position: 'relative',
  left: 'calc(50% - 46px)',
}
const payactiveOffersColumn = [
  {
    image: (
      <StaticImage
        src="../images/loesungen_assetts/bank-to-bank.png"
        alt="bank-to-bank"
        className="mb-5 black m-auto"
        style={iconStyle}
      />
    ),
    title: 'Schnell & Sicher',
    text: 'Bank-zu-Bank',
  },
  {
    image: (
      <StaticImage
        src="../images/loesungen_assetts/directonaccount.png"
        alt="direct on account"
        className="mb-5 black m-auto"
        style={iconStyle}
      />
    ),
    title: 'Direkt aufs Konto',
    text: 'Kein Clearing-Account',
  },
  {
    image: (
      <StaticImage
        src="../images/loesungen_assetts/api.png"
        alt="api"
        className="mb-5 black m-auto"
        style={iconStyle}
      />
    ),
    title: 'Easy Integriert',
    text: 'Moderne Rest-API',
  },
]

const iconStyleForColumn1: React.CSSProperties = {
  position: 'relative',
  top: '-60px',
  marginBottom: '-50px',
  width: '100px',
  left: '35%',
}

const edgyBox = (row: number) => {
  return (
    <>
      <div
        style={{
          gridColumn: 'col3-start',
          gridRow: `row${row}-start`,
          backgroundImage:
            'linear-gradient(to bottom left, transparent 50%, rgb(248 249 246 / 14%) 0), linear-gradient(to bottom right, rgb(248 249 246 / 14%) 50%, transparent 0)',
          backgroundSize: '100% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top, bottom',
          width: '2rem',
        }}
      />
      <div
        style={{
          gridColumn: 'col4-start',
          gridRow: `row${row}-start`,
          backgroundImage:
            'linear-gradient(to bottom left, rgb(248 249 246 / 14%) 50%, transparent 0), linear-gradient(to top left, rgb(248 249 246 / 14%) 50%, transparent 0)',
          backgroundSize: '100% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top, bottom',
        }}
      />
    </>
  )
}

const ProductsPage = (): React.ReactElement => {
  const { locale } = useLocalization()
  return (
    <Theme>
      <SEO title={page?.title} />
      <div className="absolute w-screen h-screen top-0 left-0 opacity-10">
        <StaticImage
          src="../images/jellyfish.jpg"
          alt="Quallen"
          objectFit="cover"
          className="w-full h-screen select-none"
          draggable={false}
        />
      </div>
      <Layout>
        <SectionHero
          from="loesungen"
          typewritter
          title={[
            'regenerative Zahlungsplattform',
            'regenerative Zahlungsplattform',
          ]}
          subtitle="payactive bietet die"
          titleTag="h1"
          text="Automatisiere deine einmaligen und wiederkehrenden Zahlungen und Rechnungen zu kosteng&uuml;nstigen Prozessen. Mit payactive zahlen deine Kund:innen leichter, schneller und mit positivem Impact."
          actionLinks={[
            {
              label: 'Demo buchen',
              url: 'demo',
            },
          ]}
        />

        <div
          className="bg-white"
          style={{
            boxShadow: '0 10px 50px rgba(0,0,0,.3)',
          }}
        >
          <SectionColumns data={payactiveOffersColumn} variant="white" />

          <div className="border-b border-black mb-2" />
          <SectionText
            from="loesungen"
            headlineVariant="greenUnderline"
            title="Reibungsloser und voll integriert: Zahlungsverkehr f&uuml;r dein Unternehmen"
            text="Die Flexibilit&auml;t der payactive Plattform erm&ouml;glicht es dir, die passenden Services f&uuml;r deinen Anwendungsfall selbst zusammenzustellen."
          />
          <SectionTableColumns
            containerClass="pt-4"
            boxClass="rounded-md p-6"
            data={paymentTransactionsColumn}
            variant="lightGrey"
          />
          <SectionTableColumns
            containerClass="pt-15 mt-1"
            boxClass="rounded-md py-5 px-6 mt-16 md:my-0"
            variant="lightGrey"
            data={[
              {
                content: (
                  <p className="text-gray-charcoal text-center">
                    Daf&uuml;r bieten wir folgende L&ouml;sungen als Services:
                  </p>
                ),
              },
            ]}
          />

          <SectionTableColumns
            gap="gap-1"
            containerClass="pt-15 mt-1 pb-20 md:pb-32"
            boxClass="rounded-md p-6"
            data={[
              {
                content: (
                  <>
                    <Headline
                      fontSize="small"
                      variant="blueUnderline"
                      className="pb-4"
                    >
                      Kaufabschluss
                    </Headline>
                    <p className="md:h-60 lg:h-40 text-gray-charcoal font-thin">
                      Einfacher Abschluss von Abos und Mitgliedschaften f&uuml;r
                      deine Kund:innen mit minimalem Intergrationsaufwand.
                    </p>
                    <button
                      onClick={() =>
                        document
                          .getElementById('subscription-service')
                          ?.scrollIntoView({
                            behavior: 'smooth',
                          })
                      }
                      className="text-gray-charcoal font-thin underline"
                      type="button"
                    >
                      Mehr zu unserem&nbsp;
                      <span className="font-bold">subscription</span>service
                    </button>
                  </>
                ),
              },
              {
                content: (
                  <>
                    <Headline
                      fontSize="small"
                      variant="blueUnderline"
                      className="pb-4"
                    >
                      Rechnungsstellung
                    </Headline>
                    <p className="md:h-60 lg:h-40 text-gray-charcoal font-thin">
                      Erstelle automatisiert oder manuell Rechnungen &uuml;ber
                      payactive und behalte dabei immer den &Uuml;berblick
                      &uuml;ber den Status deiner offenen Forderungen.
                    </p>
                    <button
                      className="text-gray-charcoal font-thin underline"
                      type="button"
                      onClick={() =>
                        document
                          .getElementById('billing-service')
                          ?.scrollIntoView({
                            behavior: 'smooth',
                          })
                      }
                    >
                      Mehr zu unserem&nbsp;
                      <span className="font-bold">billing</span>service
                    </button>
                  </>
                ),
              },
              {
                content: (
                  <>
                    <Headline
                      fontSize="small"
                      variant="blueUnderline"
                      className="pb-4"
                    >
                      Zahlungsvorg&auml;nge
                    </Headline>
                    <p className="md:h-60 lg:h-40 text-gray-charcoal font-thin">
                      Biete deinen Kund:innen verschiedene
                      Zahlungsm&ouml;glichkeit wie Rechnungszahlung,
                      Online-&Uuml;berweisungen oder Lastschrift an und erhalte
                      jede Zahlung direkt auf dein Hausbankkonto.
                    </p>
                    <button
                      className="text-gray-charcoal font-thin underline"
                      type="button"
                      onClick={() =>
                        document
                          .getElementById('payment-service')
                          ?.scrollIntoView({
                            behavior: 'smooth',
                          })
                      }
                    >
                      Mehr zu unserem&nbsp;
                      <span className="font-bold">payment</span>
                      service
                    </button>
                  </>
                ),
              },
            ]}
            variant="lightGrey"
          />
        </div>

        <SectionText
          title="payactive Pricing"
          text="Unser Verst&auml;ndnis einer modernen Zahlungsplattform ist, das sich diese an die Bed&uuml;rfnisse deines Unternehmens anpassen l&auml;sst und zudem super einfach integrierbar ist. Egal ob per Plug & Play oder per individueller Integration in deine Systeme, Du zahlst keine Setupgeb&uuml;hren, sondern nur pro Transaktion."
          headlineVariant="underline"
        />
        <Section containerClass="py-0">
          <div className="sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl mx-auto px-3">
            <div
              className="sm:block md:grid"
              style={{
                rowGap: '0.25rem',
                gridTemplateColumns:
                  '[col1-start] 3rem [col2-start] auto [col3-start] 0.25rem [col4-start] 2rem [col5-start] 16rem [col5-end]',
                gridTemplateRows:
                  '[row1-start] auto [row2-start] auto [row3-start] auto [row4-start] auto [row4-end]',
              }}
            >
              <div
                className="py-5 px-8"
                style={{
                  gridColumn: 'col1-start / col3-start',
                  gridRow: 'row1-start',
                  backgroundColor: 'rgb(248 249 246 / 14%)',
                }}
              >
                <Headline
                  fontSize="xmedium"
                  variant="underline"
                  className="pb-5"
                >
                  payment
                  <span className="font-thin" id="payment-service">
                    service
                  </span>
                </Headline>
                <h2 className="text-2xl my-2 font-serif">
                  Management von einzelnen und wiederkehrenden
                  Zahlungstransaktionen
                </h2>
                <ul>
                  <li
                    className="text-white font-thin"
                    style={{
                      listStyle: 'none',
                      background: `url(${whiteCheck}) no-repeat left center`,
                      backgroundSize: '30px',
                      padding: '0.5em 0 0.5rem 2.5rem',
                    }}
                  >
                    &Uuml;ber unser Portal oder per REST-API
                  </li>
                  <li
                    className="text-white font-thin"
                    style={{
                      listStyle: 'none',
                      background: `url(${whiteCheck}) no-repeat left center`,
                      backgroundSize: '30px',
                      padding: '0.5em 0 0.5rem 2.5rem',
                    }}
                  >
                    Digitale Zahlungsaufforderung per E-Mail
                  </li>
                  <li
                    className="text-white font-thin"
                    style={{
                      listStyle: 'none',
                      background: `url(${whiteCheck}) no-repeat left center`,
                      backgroundSize: '30px',
                      padding: '0.5em 0 0.5rem 2.5rem',
                    }}
                  >
                    Automatisches Eingangsmatching der Zahlung
                  </li>
                </ul>
                <div>
                  <p />
                </div>
                <p>Du hast &uuml;ber 50.000 Transaktionen im Monat?</p>
                <p className="font-thin">
                  Dann kontaktiere unseren Vertrieb f&uuml;r eine individuelle
                  Beratung.
                </p>
              </div>
              {edgyBox(1)}
              <div
                className="py-5 px-8 border-t border-solid md:border-none"
                style={{
                  gridColumn: 'col5-start',
                  gridRow: 'row1-start',
                  backgroundColor: 'rgb(248 249 246 / 14%)',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <div>
                  <p>
                    pro&nbsp;<span className="font-thin">Transaktion</span>
                  </p>
                  <Headline fontSize="xmedium" variant="underline">
                    1,4 %
                  </Headline>
                  <p className="my-4 font-thin">
                    vom Zahlungsbetrag min.&nbsp;0,35 &euro; | max. 2 &euro;
                  </p>
                  <p className="text-sm font-thin">
                    F&uuml;r Hochpreistransaktionen: Bei
                    Transaktionsbetr&auml;gen ab 3.000 Euro berechnen wir
                    +&nbsp;0,5% pro Transaktion.
                  </p>
                </div>
              </div>
              <div
                className="font-serif mr-1 font-thin"
                style={{
                  gridColumn: 'col1-start',
                  gridRow: 'row2-start / row4-start',
                  backgroundColor: 'rgb(248 249 246 / 14%)',
                }}
              >
                <div
                  className="invisible md:visible"
                  style={{ position: 'relative', height: '100%' }}
                >
                  <div
                    style={{
                      fontSize: '1.75rem',
                      top: '50%',
                      right: '50%',
                      position: 'absolute',
                      transform: 'scale(-1) translate(-50%, 50%)',
                      writingMode: 'vertical-lr',
                    }}
                  >
                    +&nbsp;Hinzubuchbar
                  </div>
                </div>
              </div>
              <div
                className="py-5 px-8 mt-6 md:mt-0"
                style={{
                  gridColumn: 'col2-start',
                  gridRow: 'row2-start',
                  backgroundColor: 'rgb(248 249 246 / 14%)',
                }}
              >
                <Headline
                  fontSize="xmedium"
                  variant="underline"
                  className="pb-5"
                >
                  subscription
                  <span className="font-thin" id="subscription-service">
                    service
                  </span>
                </Headline>
                <h2 className="text-2xl my-2 font-serif">
                  Wiederkehrende Zahlungsausl&ouml;sung
                </h2>
                <ul>
                  <li
                    className="text-white font-thin"
                    style={{
                      listStyle: 'none',
                      background: `url(${whiteCheck}) no-repeat left center`,
                      backgroundSize: '30px',
                      padding: '0.5em 0 0.5rem 2.5rem',
                    }}
                  >
                    Plug &amp; Play oder per REST-API
                  </li>
                  <li
                    className="text-white font-thin"
                    style={{
                      listStyle: 'none',
                      background: `url(${whiteCheck}) no-repeat left center`,
                      backgroundSize: '30px',
                      padding: '0.5em 0 0.5rem 2.5rem',
                    }}
                  >
                    Automatische Rechnung &amp; Zahlungserstellung
                  </li>
                  <li
                    className="text-white font-thin"
                    style={{
                      listStyle: 'none',
                      background: `url(${whiteCheck}) no-repeat left center`,
                      backgroundSize: '30px',
                      padding: '0.5em 0 0.5rem 2.5rem',
                    }}
                  >
                    Individuelle Einrichtung deines Abomodells
                  </li>
                </ul>
              </div>

              {edgyBox(2)}
              <div
                className="py-5 px-8 border-t border-solid md:border-none"
                style={{
                  gridColumn: 'col5-start',
                  gridRow: 'row2-start',
                  backgroundColor: 'rgb(248 249 246 / 14%)',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <div>
                  <p>
                    pro&nbsp;<span className="font-thin">Transaktion</span>
                  </p>
                  <Headline fontSize="xmedium" variant="underline">
                    + 0,6 %
                  </Headline>
                  <p className="my-4 font-thin">
                    vom Zahlungsbetrag min.&nbsp;0,35 &euro; | max. 2 &euro;
                  </p>
                </div>
              </div>
              <div
                className="mt-6 py-5 px-8 md:mt-0"
                style={{
                  gridColumn: 'col2-start',
                  gridRow: 'row3-start',
                  backgroundColor: 'rgb(248 249 246 / 14%)',
                }}
              >
                <Headline
                  fontSize="xmedium"
                  variant="underline"
                  className="pb-5"
                >
                  billing
                  <span className="font-thin" id="billing-service">
                    service
                  </span>
                </Headline>
                <h2 className="text-2xl my-2 font-serif">
                  Automatische Rechnungserstellung
                </h2>
                <ul>
                  <li
                    className="text-white font-thin"
                    style={{
                      listStyle: 'none',
                      background: `url(${whiteCheck}) no-repeat left center`,
                      backgroundSize: '30px',
                      padding: '0.5em 0 0.5rem 2.5rem',
                    }}
                  >
                    &Uuml;ber unser Portal oder per REST-API
                  </li>
                  <li
                    className="text-white font-thin"
                    style={{
                      listStyle: 'none',
                      background: `url(${whiteCheck}) no-repeat left center`,
                      backgroundSize: '30px',
                      padding: '0.5em 0 0.5rem 2.5rem',
                    }}
                  >
                    Inkl. Storno, Korrekturen und automatisiertem
                    kaufm&auml;nnischen Mahnwesen
                  </li>
                  <li
                    className="text-white font-thin"
                    style={{
                      listStyle: 'none',
                      background: `url(${whiteCheck}) no-repeat left center`,
                      backgroundSize: '30px',
                      padding: '0.5em 0 0.5rem 2.5rem',
                    }}
                  >
                    Automatisches Eingangsmatching der Rechnungen
                  </li>
                </ul>
              </div>

              {edgyBox(3)}
              <div
                className="py-5 px-8 border-t border-solid md:border-none"
                style={{
                  gridColumn: 'col5-start',
                  gridRow: 'row3-start',
                  backgroundColor: 'rgb(248 249 246 / 14%)',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <div>
                  <p>
                    pro&nbsp;<span className="font-thin">Transaktion</span>
                  </p>
                  <Headline fontSize="xmedium" variant="underline">
                    0,6 %
                  </Headline>
                  <p className="my-4 font-thin">
                    vom Zahlungsbetrag min.&nbsp;0,35 &euro; | max. 2 &euro;
                  </p>
                </div>
              </div>

              <div
                className="mt-6 py-5 px-8 md:mt-0"
                style={{
                  gridGap: '0rem',
                  gridColumn: 'col1-start / col3-start',
                  gridRow: 'row4-start',
                  backgroundColor: 'rgb(248 249 246 / 14%)',
                }}
              >
                <Headline
                  fontSize="xmedium"
                  variant="box-mix-blend"
                  className="pb-3"
                >
                  <b>social</b>cashback
                </Headline>
                <h2 className="text-2xl my-2 font-serif">
                  Immer inklusive - kostenlos f&uuml;r Unternehmen und Ihre
                  Kund:innen
                </h2>
                <p className="text-base my-2">
                  Mit jeder Transaktion Gutes tun
                </p>
                <p className="font-thin text-base">
                  Mit jeder Transaktion unterst&uuml;tzt payactive ein soziales
                  oder &ouml;kologisches Projekt - ohne
                </p>
                <p className="font-thin text-base">
                  Mehrkosten f&uuml;r dich oder deine Kund:innen.
                </p>
              </div>
              <div
                className="p-5"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gridColumn: 'col3-start / col5-end',
                  gridRow: 'row4-start',
                  backgroundColor: 'rgb(248 249 246 / 14%)',
                }}
              >
                <h2 className="mb-8 text-2xl font-serif">
                  Mit jeder <span className="font-thin">Transaktion</span>
                </h2>
                <img
                  style={{ width: '8rem' }}
                  src={socialcasback}
                  alt="socialcashback"
                />
              </div>
            </div>
            <div className="text-center md:text-left">
              <Button
                className="mt-8"
                onClick={() => navigate(`/${locale}/${removeSlashes('demo')}`)}
                variant="secondary"
              >
                Jetzt Demo buchen
              </Button>
            </div>
          </div>
        </Section>

        <SectionText title="Vorteile durch payactive" />
        <SectionTableColumns
          containerClass="pt-15 pb-20 mt-1"
          boxClass="rounded-md p-6"
          data={[
            {
              content: (
                <>
                  <StaticImage
                    src="../images/loesungen_assetts/besserer-cashflowicon.png"
                    alt="chashflow"
                    className="mb-5 black m-auto"
                    style={iconStyleForColumn1}
                  />

                  <Headline
                    fontSize="small"
                    variant="blueUnderline"
                    className="pb-4"
                  >
                    Besserer Cashflow
                  </Headline>
                  <p className="text-gray-charcoal font-thin">
                    Mit payactive landet das Geld deiner Kund:innen direkt auf
                    deinem Konto, ohne Umwege &uuml;ber ein Clearing-Konto.
                  </p>
                </>
              ),
            },
            {
              content: (
                <>
                  <StaticImage
                    src="../images/loesungen_assetts/plug&playicon.png"
                    alt="Platzhalter"
                    className="mb-5 black m-auto bg"
                    style={iconStyleForColumn1}
                  />
                  <Headline
                    fontSize="small"
                    variant="blueUnderline"
                    className="pb-4"
                  >
                    Plug &amp; Play
                  </Headline>
                  <p className="text-gray-charcoal font-thin">
                    Starte direkt mit unseren L&ouml;sungen, damit deine
                    Kund:innen Produkte, Abos und Mitgliedsbeitr&auml;ge zahlen
                    k&ouml;nnen.
                  </p>
                </>
              ),
            },
            {
              content: (
                <>
                  <StaticImage
                    src="../images/loesungen_assetts/sicher&einfachicon.png"
                    alt="Platzhalter"
                    className="mb-5 black m-auto"
                    style={iconStyleForColumn1}
                  />
                  <Headline
                    fontSize="small"
                    variant="blueUnderline"
                    className="pb-4"
                  >
                    Sicher &amp; Einfach
                  </Headline>
                  <p className="text-gray-charcoal font-thin">
                    Open-Banking erm&ouml;glicht sichere und ein-fache Zahlungen
                    &uuml;ber deine Hausbank. Kein Wechsel und kein
                    Umgew&ouml;hnen n&ouml;tig.
                  </p>
                </>
              ),
            },
          ]}
          variant="lightGrey"
        />
        <div
          style={{
            backgroundColor: 'rgb(38 38 38 / 80%)',
            position: 'relative',
          }}
        >
          <SectionText
            containerClass="pt-14 pb-0"
            title="Voll integrierbar via Rest API"
            headlineVariant="underline"
          />
          <SectionInfo
            containerClass="pb-14 md:pb-20"
            title="In drei Schritten live:"
            titleTag="h3"
            from="home-api"
            image={<img src={codeGif} alt="Code sequence" />}
            actionLinks={[
              {
                label: 'Zur API Dokumentation',
                url: 'https://docs.payactive.io/apidoc',
                variant: 'tertiary',
              },
            ]}
            bullets={[
              {
                title: 'API Key Generieren',
                text: 'Melde dich im payactive Portal an und generiere dir einfach und unkompliziert deinen API Code.',
              },
              {
                title: 'API Key integrieren',
                text: 'Verwende den API Key, um Anfragen an die payactive Plattform zu authentifizieren.',
              },
              {
                title: 'Invoices senden & Payments empfangen',
                text: 'Sende per API sicher und schnell Rechnungen oder Empfange Zahlungen deiner Kunden.',
              },
            ]}
          />
        </div>
        <SectionText title="Unternehmen die auf uns Vertrauen" />
        <SectionTableColumns
          containerClass="pt-15 mt-1"
          boxClass="rounded-md p-6"
          variant="lightGrey"
          data={[
            {
              content: (
                <>
                  <div className="block md:flex">
                    <div className="m-auto w-40 md:w-60 md:grid-rows-1 justify-center w-62">
                      <StaticImage
                        src="../images/loesungen_assetts/rec_hero.png"
                        alt="one-time-payments_icon"
                      />
                    </div>
                    <div className="text-center relative text-sm md:flex flex-col pb-5 pt-9 md:pl-6 sm:my-4">
                      <Headline
                        variant="blueUnderline"
                        inverted
                        fontSize="medium"
                      >
                        Wertstoffe einsammeln im Abo
                      </Headline>

                      <p className="text-lg text-gray-charcoal mt-6 md:text-left pl-5">
                        Alessandro von&nbsp;
                        <strong>Recyclehero</strong>
                      </p>
                      <a
                        className="text-lg text-brand underline md:text-left text-gray-charcoal pl-5 pt-4"
                        href="/de/blog/payactive-zahlungsplattform-recyclehero"
                      >
                        Story lesen
                      </a>
                    </div>
                    <div className="hidden md:block ml-auto self-center w-5 ">
                      <a href="/de/blog/payactive-zahlungsplattform-recyclehero">
                        <StaticImage
                          src="../images/loesungen_assetts/small-arrow.png"
                          alt="small_arrow"
                        />
                      </a>
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        />
        {/* <SectionTableColumns
          containerClass="pt-15 mt-1"
          boxClass="rounded-md p-6  sm:flex-row flex-wrap items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-10"
          data={[
            {
              content: (
                <>
                  <p>
                    <strong>Weitere Usecases entdecken :</strong>
                  </p>
                  <div className="flex space-x-4">
                    <div className="border border-sky-500 rounded underline p-1">
                      <a  href="/de/nachhaltigkeit">
                        Hohe Transaktionsbeträge
                      </a>
                    </div>
                    <div className="border border-sky-500 rounded underline p-1 ">
                      <a  href="/de/nachhaltigkeit">Abomodelle</a>
                    </div>
                    <div className="border border-sky-500 rounded underline p-1">
                      <a  href="/de/nachhaltigkeit">
                        Rechnungszahler
                      </a>
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        /> */}

        <SectionBanner
          title="Gutes tun beim Geld verdienen"
          text="Wir sind &uuml;berzeugt, dass wir mit payactive eine ganz au&szlig;ergew&ouml;hnliche L&ouml;sung f&uuml;r Zahlungsprozesse mit Impact geschaffen haben. Wir w&uuml;rden dir gerne zeigen, was wir darunter verstehen."
          subtext="Manuel Born, Sales-Expert von payactive"
          image={
            <StaticImage
              src="../images/manuwithstars.png"
              alt="Person mit Glitzer"
              style={{ maxWidth: 450 }}
            />
          }
          actionLinks={[
            {
              label: 'Termin buchen',
              url: 'termin-buchen',
            },
            {
              label: 'Per E-Mail kontaktieren',
              url: 'mailto:hello@payactive.eu?subject=Request for information',
              variant: 'link',
            },
          ]}
        />
        <SectionNewsletter containerClass="" page="Startseite" />
      </Layout>
    </Theme>
  )
}

export default ProductsPage
